.phoneInput > input {
  display: none;
  width: 80px;
  border: none;
}
.phoneInput {
  width: 50px !important;
}

.profile-photo {
  border-radius: 10px;
}
.bTn {
  background-color: #9fe2bf;
  cursor: pointer;
  color: #000 !important;
}
.bTn:hover {
  background-color: #2fc477 !important;
  color: #000 !important;
}
.ebsj {
  background-color: #24c452ce;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.search-input {
  height: 40px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 5px;
  margin-left: 6px;
  padding: 5px;
}
.search-container {
  position: relative;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #555;
  cursor: pointer;
}
.range-picker-input {
  height: 55px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 10px;
  padding: 3px;
  background-color: #fff;
  /* opacity: 0.6; */
  margin-right: 15px;
}

.report-modal .modal-dialog {
  max-width: 800px !important;
}

.incidence-details :hover {
  background-color: #2fc477;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.incidence-details {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.complaint-image img {
  min-width: 120px;
}
.image-view-modal div {
  height: 700px;
  width: 80vw;
}
.image-view-modal img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  max-width: none;
}
.open-status {
  border-left: none !important;
}
.process-status {
  border-left: 3px solid gainsboro !important;
}
.location-icon {
  background: #3e4954;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  border-radius: 56px;
}
.google-map img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
