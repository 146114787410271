.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}

.cross {
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background-color: #ecf0f3 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}
.cross:hover {
  background-color: #fff !important;
}
